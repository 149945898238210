import React from "react";
import { LINK_APPOINTMENTS, LINK_BOOKKEEPING, LINK_CUSTOMERS, LINK_EMPLOYEES, LINK_ESTIMATES, LINK_INVOICES, LINK_PARTS_ACCESSORIES, LINK_SKETCHES } from "../../../config";
import "./Styles.css";

export class DashboardSideNavComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="dashboard-sidenav-component-wrapper">
          <div className="sidenav">
            <a className="sidenav-title" href="/dashboard">
              <i className="fas fa-tachometer-alt pr-2"></i>Dashboard
            </a>
            <a href={LINK_CUSTOMERS}>
              Customers<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_EMPLOYEES}>
              Employees<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_APPOINTMENTS}>
              Appointments<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_SKETCHES}>
              Sketches<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_ESTIMATES}>
              Estimates<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_INVOICES}>
              Invoices<i className="fas fa-chevron-right float-right"></i>
            </a>
            <a href={LINK_PARTS_ACCESSORIES}>
              Parts & Accessories
              <i className="fas fa-chevron-right float-right"></i>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
