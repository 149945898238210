import React from "react";
import "./Styles.css";
import {LINK_LOGIN} from "../../../config"

export class UnauthenticatedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.history.push({pathname: LINK_LOGIN});
  }

  render() {
    return (
      <React.Fragment></React.Fragment>
    );
  }
}
