import React from "react";
import "./Styles.css";

export class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="header-component-wrapper">
          <header className="masthead bg-primary text-white text-center">
            <div className="container d-flex align-items-center flex-column">
              <img
                className="masthead-avatar mb-5"
                src="img/avataaars.svg"
                alt=""
              ></img>

              <h1 className="masthead-heading mb-0">Gutter Pro App</h1>

              <div className="divider-custom divider-light">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>

              <p className="masthead-subheading font-weight-light mb-0">
                Lorem Ipsum - Lorem Ipsum - Lorem Ipsum
              </p>
            </div>
          </header>
        </div>
      </React.Fragment>
    );
  }
}
