import React from "react";
import "./Styles.css";
import { ENDPOINT_INVOICE, LINK_DASHBOARD, MAPPING_DELETEONE, MAPPING_GETONE, MAPPING_SAVEONE, CANCEL, DELETE, SAVE, NEW_ENTRY } from "../../../config/properties";
import { getData, postData } from "../../../services"
import { FormButtonGroup } from "../formButtonGroup/FormButtonGroup";

export class InvoiceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.params,
      history: this.props.history,
      isNewEntry: false,
      invoice: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.params.id === NEW_ENTRY) {
      this.setState({ ...this.state, isNewEntry: true });
    } else {
      let path = ENDPOINT_INVOICE + MAPPING_GETONE + "/" + this.state.params.id;
      getData(path)
        .then((data) => {
          this.setState({ ...this.state, invoice: data });
        });
    }
  }

  async handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ ...this.state, invoice: { ...this.state.invoice, [name]: value } });
  }

  handleSubmit(event, action) {
    event.preventDefault();
    let path = null;
    switch (action) {
      case DELETE:
        path = ENDPOINT_INVOICE + MAPPING_DELETEONE;
        postData(path, this.state.appointment)
          .then((data) => {
            console.log(data);
          });
        this.state.history.push(LINK_DASHBOARD);
        break;
      case CANCEL:
        this.state.history.push(LINK_DASHBOARD);
        break;
      case SAVE:
        path = ENDPOINT_INVOICE + MAPPING_SAVEONE;
        postData(path, this.state.appointment)
          .then((data) => {
            console.log(data);
          });
        this.state.history.push(LINK_DASHBOARD);
        break;
      default:
        break;
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className="dashboard-bookkeeping-component-wrapper">
          <section className="page-section" id="contact">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary mb-0">
                Invoice
              </h2>
              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>

              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <form name="sentMessage" id="invoiceForm" onSubmit={this.handleSubmit}>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>itemName</label>
                        <input
                          className="form-control"
                          id="itemName"
                          name="itemName"
                          type="text"
                          placeholder="itemName"
                          data-validation-required-message="Please enter itemName."
                          onChange={this.handleChange}
                          value={
                            (this.state.invoice &&
                              this.state.invoice.itemName) ||
                            ""
                          }
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Comments</label>
                        <textarea
                          className="form-control"
                          id="comments"
                          name="comments"
                          rows={5}
                          placeholder="Comments"
                          data-validation-required-message="Please enter comments."
                          onChange={this.handleChange}
                          value={
                            (this.state.invoice &&
                              this.state.invoice.comments) ||
                            ""
                          }
                        ></textarea>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <br></br>
                    <div id="success"></div>
                    <FormButtonGroup handleSubmit={this.handleSubmit}></FormButtonGroup>
                  </form>
                </div>
              </div>

            </div>
          </section>
        </div>
      </React.Fragment >
    );
  }
}
