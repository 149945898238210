import React from "react";
import "./Styles.css";

export class FooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="footer-component-wrapper">
          <footer className="footer text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <h4 className="mb-4">Location</h4>
                  <p className="lead mb-0">
                    123 Main Street
                    <br></br>Boston, MA 02101
                  </p>
                </div>

                <div className="col-lg-4 mb-5 mb-lg-0">
                  <h4 className="mb-4">Around the Web</h4>
                  <a className="btn btn-outline-light btn-social mx-1" href="#">
                    <i className="fab fa-fw fa-facebook-f"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social mx-1" href="#">
                    <i className="fab fa-fw fa-twitter"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social mx-1" href="#">
                    <i className="fab fa-fw fa-linkedin-in"></i>
                  </a>
                  <a className="btn btn-outline-light btn-social mx-1" href="#">
                    <i className="fab fa-fw fa-instagram"></i>
                  </a>
                </div>

                <div className="col-lg-4">
                  <h4 className="mb-4">About</h4>
                  <p className="lead mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </footer>
          <section className="copyright py-4 text-center text-white">
            <div className="container">
              <small>Copyright &copy; Your Website 2019</small>
            </div>
          </section>

          <div className="scroll-to-top d-lg-none position-fixed ">
            <a
              className="js-scroll-trigger d-block text-center text-white rounded"
              href="#page-top"
            >
              <i className="fa fa-chevron-up"></i>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
