import React from "react";
import "./Styles.css";

export class HomeContactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="home-contact-component-wrapper">
          <section className="page-section" id="contact">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary mb-0">
                Contact
              </h2>

              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>

              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <form name="sentMessage" id="contactForm">
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Name</label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          placeholder="Name"
                          data-validation-required-message="Please enter your name."
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Email Address</label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="Email Address"
                          data-validation-required-message="Please enter your email address."
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Phone Number</label>
                        <input
                          className="form-control"
                          id="phone"
                          type="tel"
                          placeholder="Phone Number"
                          data-validation-required-message="Please enter your phone number."
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Message</label>
                        <textarea
                          className="form-control"
                          id="message"
                          rows={5}
                          placeholder="Message"
                          data-validation-required-message="Please enter a message."
                        ></textarea>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <br></br>
                    <div id="success"></div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-xl"
                        id="sendMessageButton"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
