import React from "react";
import "./Styles.css";

export class AboutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="about-component-wrapper">
          <section className="page-section mb-0" id="about">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary">
                About
              </h2>

              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>

              <div className="row">
                <div className="col-lg-4 ml-auto">
                  <p className="lead">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                    <br></br>
                    <br></br>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
                <div className="col-lg-4 mr-auto">
                  <img
                    className="masthead-avatar mb-5"
                    src="img/avataaars.svg"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
