export const columns = [
    { title: "id", field: "id" },
    { title: "First name", field: "firstName" },
    { title: "Last name", field: "lastName" },
    { title: "Address", field: "address" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    { title: "State", field: "zip", hidden: true },
    { title: "State", field: "phone", hidden: true },
    { title: "State", field: "email", hidden: true },
  ];