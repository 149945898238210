import React from "react";
import "./Styles.css";

export class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="error-component-wrapper">
          <h1 className="text-center">Sorry No Results!</h1>
        </div>
      </React.Fragment>
    );
  }
}
