import React from "react";
import "./Styles.css";
import { LoggedInComponent } from "./LoggedInComponent"
import { LINK_ABOUT, LINK_CONTACT, LINK_HOME, LINK_SERVICES } from "../../../config"

export class NavbarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUser: this.props.appUser,
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="navbar-component-wrapper">
          <nav
            className="navbar navbar-expand-lg bg-secondary fixed-top"
            id="mainNav"
          >
            <div className="container">
              <a className="navbar-brand js-scroll-trigger" href={LINK_HOME}>
                Gutter Stack
              </a>
              <button
                className="navbar-toggler navbar-toggler-right font-weight-bold bg-primary text-white rounded"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                Menu
                <i className="fas fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item mx-0 mx-lg-1">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href={LINK_ABOUT}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href={LINK_SERVICES}
                    >
                      Services
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1">
                    <a
                      className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                      href={LINK_CONTACT}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item mx-0 mx-lg-1">
                    <LoggedInComponent appUser={this.state.appUser}></LoggedInComponent>
                  </li>
                </ul>
              </div>
              </div>
          </nav>
        </div>
      </React.Fragment>
        );
  }
}
