import React from "react";
import "./Styles.css";
import { LINK_LOGIN, LINK_DASHBOARD, LINK_HOME, LINK_ACCOUNT } from "../../../config"

export class LoggedInComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appUser: this.props.appUser,
    };
  }
  render() {
    return (
      <React.Fragment>
        {this.state.appUser ?
          <div className="dropdown">
            <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Account
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href={LINK_DASHBOARD}>Dashboard</a>
              <a className="dropdown-item" href={LINK_ACCOUNT + "/" + this.state.appUser.id}>Account</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href={LINK_HOME}>Log out</a>
            </div>
          </div>
          :
          <a
            className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
            href={LINK_LOGIN}
          >
            Login
          </a>
        }
      </React.Fragment>
    );
  }
}
