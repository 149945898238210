import React from "react";
import "./Styles.css";
import { ENDPOINT_AUTHENTICATE, LINK_DASHBOARD } from "../../../config"

export class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoginAttempt: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() { }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      ...this.state,
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      isLoginAttempt: true
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: this.state.username, password: this.state.password }),
    };
    fetch(ENDPOINT_AUTHENTICATE, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.jwt) {
          localStorage.setItem("jwt", data.jwt);
          this.props.history.push({
            pathname: LINK_DASHBOARD
          });
        }
      })
      .catch((error) => { })
      .finally(() => {
        this.setState({
          ...this.state,
          isLoginAttempt: false
        });
       });
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-component-wrapper">
          <section className="page-section" id="contact">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary mb-0">
                Login
              </h2>

              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>

              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <form name="sentMessage" id="loginForm" onSubmit={this.handleSubmit}>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Username</label>
                        <input
                          className="form-control"
                          id="username"
                          type="text"
                          placeholder="Username"
                          name="username"
                          data-validation-required-message="Please enter your username."
                          value={this.state.username} onChange={this.handleChange}
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="form-group floating-label-form-group controls mb-0 pb-2">
                        <label>Password</label>
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          placeholder="Password"
                          name="password"
                          data-validation-required-message="Please enter your password."
                          value={this.state.password} onChange={this.handleChange}
                        ></input>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <br></br>
                    <div id="success"></div>
                    <div className="form-group">
                      {!this.state.isLoginAttempt ?
                        <button
                          type="submit"
                          className="btn btn-primary btn-xl"
                          id="loginButton"
                        >
                          Login
                        </button>
                        :
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
