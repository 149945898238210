import React from "react";
import {
  NavbarComponent,
  FooterComponent,
  DashboardSideNavComponent,
  InvoiceComponent,
} from "../../../components";
import { validateAppUser } from "../../../services";
import { ENDPOINT_APPUSER, MAPPING_GETALL } from "../../../config";
import { UnauthenticatedPage } from ".."

export class InvoicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match && this.props.match.params,
      history: this.props.history,
      isAuthenticatedUser: false,
      isLoading: true,
      appUser: null,
    };
  }

  componentDidMount() {
    let path = ENDPOINT_APPUSER + MAPPING_GETALL;
    validateAppUser(path)
      .then((data) => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: true,
          isLoading: false,
          appUser: data[0],
        });
      })
      .catch(error => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: false,
          isLoading: false
        });
      });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <React.Fragment></React.Fragment >
      );
    } else {
      if (this.state.isAuthenticatedUser === false) {
        return (
          <React.Fragment>
            <UnauthenticatedPage history={this.state.history}></UnauthenticatedPage>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="invoice-page-wrapper">
              <NavbarComponent appUser={this.state.appUser}></NavbarComponent>
              <div className="row">
                <div className="col-2">
                  <DashboardSideNavComponent></DashboardSideNavComponent>
                </div>
                <div className="col-10">
                  <InvoiceComponent
                    params={this.state.params}
                    history={this.state.history}
                  ></InvoiceComponent>
                </div>
              </div>
              <FooterComponent></FooterComponent>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}
