import React from "react";
import "./Styles.css";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { tableIcons } from "./TableIcons"

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.pathname,
      columns: this.props.columns,
      data: this.props.data,
    };
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ ...this.state, data: props.data });
  }

  handleRowClick(event, rowData) {
    const path = this.state.pathname + "/" + rowData.id;
    this.props.history.push(path);
  }

  render() {
    return (
      <div>
        <MaterialTable
          columns={this.state.columns}
          data={this.state.data}
          icons={tableIcons}
          options={{
            /*
            filtering: true,
            */
            sorting: true,
            showTitle: false,
          }}
          onRowClick={(event, row) => this.handleRowClick(event, row)}
        />
      </div>
    );
  }
}

export const TableComponentWithRouter = withRouter(TableComponent);
