import React from "react";
import "./Styles.css";
import { LINK_APPOINTMENTS, LINK_CUSTOMERS, LINK_EMPLOYEES, LINK_ESTIMATES, LINK_INVOICES, LINK_PARTS_ACCESSORIES, LINK_SKETCHES } from "../../../config"

export class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="dashboard-component-wrapper">
          <section className="page-section" id="contact">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary mb-0">
                Dashboard
              </h2>
              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Customers</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_CUSTOMERS}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Employees</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_EMPLOYEES}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Appointments</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_APPOINTMENTS}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Sketches</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_SKETCHES}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Estimates</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_ESTIMATES}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Invoices</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_INVOICES}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card dashboard-card mb-4">
                    <div className="card-body">Parts & Accessories</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                      <a
                        className="small stretched-link"
                        href={LINK_PARTS_ACCESSORIES}
                      >
                        View Details
                      </a>
                      <div className="small">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
