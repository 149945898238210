import React from "react";
import { CANCEL, DELETE, SAVE } from "../../../config/properties";
import "./Styles.css";

export class FormButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleSubmit: this.props.handleSubmit,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-danger btn-xl mr-3"
            id="deleteButton"
            onClick={event => this.state.handleSubmit(event, DELETE)}
          >
            Delete
          </button>
          <button
            type="submit"
            className="btn btn-secondary btn-xl mr-3"
            id="cancelButton"
            onClick={event => this.state.handleSubmit(event, CANCEL)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-xl"
            id="saveButton"
            onClick={event => this.state.handleSubmit(event, SAVE)}
          >
            Save
          </button>
        </div>
      </React.Fragment >
    );
  }
}
