import React from "react";
import {
  NavbarComponent,
  FooterComponent,
  ErrorComponent,
} from "../../../components";
import { validateAppUser } from "../../../services";
import { ENDPOINT_APPUSER, MAPPING_GETALL } from "../../../config";

export class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticatedUser: false,
      isLoading: true,
      appUser: null,
    };
  }

  componentDidMount() {
    let path = ENDPOINT_APPUSER + MAPPING_GETALL;
    validateAppUser(path)
      .then((data) => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: true,
          isLoading: false,
          appUser: data[0],
        });
      })
      .catch(error => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: false,
          isLoading: false
        });
      });
  }
    render() {
    if (this.state.isLoading === true) {
      return (
        <React.Fragment></React.Fragment >
      );
    } else {
    return (
      <div className="error-page-wrapper">
            <NavbarComponent appUser={this.state.appUser}></NavbarComponent>
        <ErrorComponent></ErrorComponent>
        <FooterComponent></FooterComponent>
      </div>
    );
  }
}
}
