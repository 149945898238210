//export const ENDPOINT_ROOT = "http://localhost:8000";
export const ENDPOINT_ROOT = "https://gutterstack.herokuapp.com";
export const ENDPOINT_AUTHENTICATE = ENDPOINT_ROOT + "/api/authenticate";
export const ENDPOINT_APPUSER = ENDPOINT_ROOT + "/api/app-user";
export const ENDPOINT_CUSTOMER = ENDPOINT_ROOT + "/api/customer";
export const ENDPOINT_EMPLOYEE = ENDPOINT_ROOT + "/api/employee";
export const ENDPOINT_APPOINTMENT = ENDPOINT_ROOT + "/api/appointment";
export const ENDPOINT_ESTIMATE = ENDPOINT_ROOT + "/api/estimate";
export const ENDPOINT_INVOICE = ENDPOINT_ROOT + "/api/invoice";
export const ENDPOINT_PARTACCESSORY = ENDPOINT_ROOT + "/api/part-accessory";
export const ENDPOINT_SKETCH = ENDPOINT_ROOT + "/api/sketch";

export const MAPPING_GETONE = "/getOne";
export const MAPPING_GETALL = "/getAll";
export const MAPPING_SAVEONE = "/saveOne";
export const MAPPING_SAVEALL = "/saveAll";
export const MAPPING_DELETEONE = "/deleteOne";
export const MAPPING_DELETEALL = "/deleteAll";

export const LINK_HOME = "/home";
export const LINK_ABOUT = "/about";
export const LINK_SERVICES = "/services";
export const LINK_CONTACT = "/contact";
export const LINK_LOGIN = "/login";
export const LINK_ERROR = "/error";
export const LINK_DASHBOARD = "/dashboard";
export const LINK_ACCOUNT = LINK_DASHBOARD + "/account";
export const LINK_CUSTOMER = LINK_DASHBOARD + "/customer";
export const LINK_CUSTOMERS = LINK_DASHBOARD + "/customers";
export const LINK_EMPLOYEE = LINK_DASHBOARD + "/employee";
export const LINK_EMPLOYEES = LINK_DASHBOARD + "/employees";
export const LINK_APPOINTMENT = LINK_DASHBOARD + "/appointment";
export const LINK_APPOINTMENTS = LINK_DASHBOARD + "/appointments";
export const LINK_ESTIMATE = LINK_DASHBOARD + "/estimate";
export const LINK_ESTIMATES = LINK_DASHBOARD + "/estimates";
export const LINK_INVOICE = LINK_DASHBOARD + "/invoice";
export const LINK_INVOICES = LINK_DASHBOARD + "/invoices";
export const LINK_PART_ACCESSORY = LINK_DASHBOARD + "/part-accessory";
export const LINK_PARTS_ACCESSORIES = LINK_DASHBOARD + "/parts-accessories";
export const LINK_REPORT = LINK_DASHBOARD + "/report";
export const LINK_REPORTS = LINK_DASHBOARD + "/reports";
export const LINK_BOOKKEEPING = LINK_DASHBOARD + "/bookkeeping";
export const LINK_SKETCH = LINK_DASHBOARD + "/sketch";
export const LINK_SKETCHES = LINK_DASHBOARD + "/sketches";

export const SAVE = "SAVE";
export const CANCEL = "CANCEL";
export const DELETE = "DELETE";
export const GET = "GET";
export const POST = "POST";
export const NEW_ENTRY = "new-entry";