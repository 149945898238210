import { GET, POST } from "../config/properties";

export async function validateAppUser(path) {
    const jwt = localStorage.getItem("jwt");
    const requestOptions = {
        method: GET,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
        }
    };
    return fetch(path, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
}

export async function getData(path) {
    const jwt = localStorage.getItem("jwt");
    const requestOptions = {
        method: GET,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
        }
    };
    return fetch(path, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
}

export async function postData(path, jsonObject) {
    const jwt = localStorage.getItem("jwt");
    const requestOptions = {
        method: POST,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
        },
        body: JSON.stringify(jsonObject),
    };
    return fetch(path, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
}