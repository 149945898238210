import React from "react";
import "./Styles.css";
import { ENDPOINT_CUSTOMER, LINK_CUSTOMER, MAPPING_GETALL, NEW_ENTRY } from "../../../config/properties";
import { TableComponentWithRouter } from "../table/TableComponent";
import { columns } from "./CustomerColumns";
import { getData, postData } from "../../../services"

export class CustomersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
    };
  }

  componentDidMount() {
    let path = ENDPOINT_CUSTOMER + MAPPING_GETALL;
    getData(path)
      .then((data) => {
        this.setState({ ...this.state, customers: data });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="dashboard-customers-component-wrapper">
          <section className="page-section" id="contact">
            <div className="container">
              <h2 className="page-section-heading text-center text-secondary mb-0">
                Customers
              </h2>
              <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon">
                  <i className="fas fa-star"></i>
                </div>
                <div className="divider-custom-line"></div>
              </div>
              <a
                type="submit"
                className="btn btn-primary text-light px-3 mb-2"
                id="sendMessageButton"
                href={LINK_CUSTOMER + "/" + NEW_ENTRY}
              >
                <i className="fas fa-plus mr-2"></i>New
              </a>
              <TableComponentWithRouter
                pathname={LINK_CUSTOMER}
                columns={columns}
                data={this.state.customers}
              ></TableComponentWithRouter>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
