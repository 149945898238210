import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LINK_ABOUT, LINK_ACCOUNT, LINK_APPOINTMENT, LINK_APPOINTMENTS, LINK_CONTACT, LINK_CUSTOMER, LINK_CUSTOMERS, LINK_DASHBOARD, LINK_EMPLOYEE, LINK_EMPLOYEES, LINK_ESTIMATE, LINK_ESTIMATES, LINK_HOME, LINK_INVOICE, LINK_INVOICES, LINK_LOGIN, LINK_PARTS_ACCESSORIES, LINK_PART_ACCESSORY, LINK_SERVICES, LINK_SKETCH, LINK_SKETCHES } from "./config";
import {
  HomePage,
  AboutPage,
  ContactPage,
  ServicesPage,
  LoginPage,
  ErrorPage,
  DashboardPage,
  AppointmentPage,
  AppointmentsPage,
  CustomerPage,
  CustomersPage,
  EmployeePage,
  EmployeesPage,
  EstimatePage,
  EstimatesPage,
  InvoicePage,
  InvoicesPage,
  PartAccessoryPage,
  PartsAccessoriesPage,
  SketchPage,
  SketchesPage,
  AccountPage
} from "./pages";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path={LINK_HOME} component={HomePage} />
          <Route exact path={LINK_ABOUT} component={AboutPage} />
          <Route exact path={LINK_SERVICES} component={ServicesPage} />
          <Route exact path={LINK_CONTACT} component={ContactPage} />
          <Route exact path={LINK_LOGIN} component={LoginPage} />
          <Route exact path={LINK_DASHBOARD} component={DashboardPage} />
          <Route exact path={LINK_DASHBOARD} component={DashboardPage} />
          <Route
            exact
            path={LINK_ACCOUNT + "/:id"}
            component={AccountPage}
          />
          <Route
            exact
            path={LINK_CUSTOMER + "/:id"}
            component={CustomerPage}
          />
          <Route
            exact
            path={LINK_CUSTOMERS}
            component={CustomersPage}
          />
          <Route
            exact
            path={LINK_EMPLOYEE + "/:id"}
            component={EmployeePage}
          />
          <Route
            exact
            path={LINK_EMPLOYEES}
            component={EmployeesPage}
          />
          <Route
            exact
            path={LINK_APPOINTMENT + "/:id"}
            component={AppointmentPage}
          />
          <Route
            exact
            path={LINK_APPOINTMENTS}
            component={AppointmentsPage}
          />
          <Route
            exact
            path={LINK_ESTIMATE + "/:id"}
            component={EstimatePage}
          />
          <Route
            exact
            path={LINK_ESTIMATES}
            component={EstimatesPage}
          />
          <Route
            exact
            path={LINK_INVOICE + "/:id"}
            component={InvoicePage}
          />
          <Route
            exact
            path={LINK_INVOICES}
            component={InvoicesPage}
          />
          <Route
            exact
            path={LINK_PART_ACCESSORY + "/:id"}
            component={PartAccessoryPage}
          />
          <Route
            exact
            path={LINK_PARTS_ACCESSORIES}
            component={PartsAccessoriesPage}
          />
          <Route
            exact
            path={LINK_SKETCH + "/:id"}
            component={SketchPage}
          />
          <Route
            exact
            path={LINK_SKETCHES}
            component={SketchesPage}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
