import React from "react";
import {
  NavbarComponent,
  FooterComponent,
  ServicesComponent,
} from "../../../components";
import { validateAppUser } from "../../../services";
import { ENDPOINT_APPUSER, MAPPING_GETALL } from "../../../config";

export class ServicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticatedUser: false,
      isLoading: true,
      appUser: null,
    };
  }

  componentDidMount() {
    let path = ENDPOINT_APPUSER + MAPPING_GETALL;
    validateAppUser(path)
      .then((data) => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: true,
          isLoading: false,
          appUser: data[0],
        });
      })
      .catch(error => {
        this.setState({
          ...this.state,
          isAuthenticatedUser: false,
          isLoading: false
        });
      });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <React.Fragment></React.Fragment >
      );
    } else {
    return (
      <React.Fragment>
        <div className="services-page-wrapper">
        <NavbarComponent appUser={this.state.appUser}></NavbarComponent>
          <ServicesComponent></ServicesComponent>
          <FooterComponent></FooterComponent>
        </div>
      </React.Fragment>
    );
  }
}
}
